import { Controller } from "stimulus"

import Basket from '../utils/basket'

export default class extends Controller {
  static targets = ["items", "total", "emptyMessage", "checkoutButton", "view", "checkout", "heading", "formEncodedOrder", "orderField"]

  initialize() {
    this.basket = Basket.global()
    this.refresh()
  }

  refresh() {
    const $items = $(this.itemsTarget)
    const $emptyMessage = $(this.emptyMessageTarget)
    const $view = $(this.viewTarget)

    if (this.basket.items.length < 1) {
      $view.hide()
      $emptyMessage.show()
    } else {
      $emptyMessage.hide()
      $view.show()
      
      $items.html('')

      let total = 0

      this.basket.items.forEach(item => {
        let subtotal = item.price * item.quantity
        total += subtotal

        $items.append(`
          <div class="row basket__row align-center">
            <div class="large-7 small-12 columns">
              <a class="basket__product-name" href="${item.url || "#"}">${item.product}</a></td>
              <p>${item.variant}</p>
            </div>
            <div class="large-3 small-6 columns">
              <input type="number" data-action="change->basket#changeQuantity" name="${item.sku}" value="${item.quantity}" />

              <a href="javascript:void(0)" data-item="${item.sku}" data-action="click->basket#remove" class="basket__remove"><i class="fa fa-trash"></i></a>
            </div>
            <div class="large-2 small-6 columns text-right">
              <span class="basket__price">£${subtotal.toMoney(2)}</span>
            </div>
          </tr>
        `)
      })

      $items.append(`
        <div class="row basket__total-row">
          <div class="large-2 small-6 large-offset-8 text-right columns">
            <strong>Total: </strong>
          </div>
          <div class="large-2 small-6 text-right columns">
            <span class="basket__total">£${total.toMoney(2)}</span>
          </div>
        </tr>
      `)
    }
  }

  changeQuantity(e) {
    const sku = e.target.attributes['name'].value
    this.basket.setQuantity({}, sku, parseInt(e.target.value))

    this.refresh()
  }

  remove(e) {
    const sku = e.currentTarget.getAttribute('data-item')
    this.basket.remove(sku)

    this.refresh()
  }

  checkout() {
    $(this.viewTarget).hide()
    $(this.checkoutTarget).show()
    $(this.headingTarget).text("Checkout")

    let orderText = ""
    let total = 0
    let itemCount = 0

    this.basket.items.forEach(item => {
      let subtotal = item.price * item.quantity
      total += subtotal
      itemCount += item.quantity

      orderText += `${item.product}
- ${item.variant}
Quantity: ${item.quantity}, Total: £${subtotal.toMoney(2)}

`
    })

    orderText += `======================
Items: ${itemCount}
Total: £${total.toMoney(2)}
`

    this.orderFieldTarget.value = orderText
  }
}