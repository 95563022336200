import { Controller } from "stimulus"

import Basket from '../utils/basket'

export default class extends Controller {
  static targets = ["qty", "soleVariantId"]

  initialize() {
    this.basket = Basket.global()

    this.qty = 1
    this.refreshQuantity()

    this.variant = ""
    this.variantPrice = 0
    this.variantId = null

    // Handle case where product has no options or variants
    if (this.soleVariantIdTarget) {
      this.variantId = this.soleVariantIdTarget.value
      this.variantPrice = parseFloat(this.soleVariantIdTarget.getAttribute('data-price'))
    }
  }

  updateQuantity() {
    const existingQty = this.basket.quantity(this.variantId) || 0
    const newQty = this.qty + existingQty

    this.basket.setQuantity({
      product: this.data.get('name'),
      url: this.data.get('url'),
      variant: this.variant,
      price: this.variantPrice
    }, this.variantId, newQty)

    this.refreshQuantity()
  }

  refreshQuantity() {
    this.qtyTarget.value = this.qty
  }

  increment() {
    this.qty += 1
    this.refreshQuantity()
  }

  decrement() {
    if (this.qty > 0) {
      this.qty -= 1
      this.refreshQuantity()
    }
  }

  setQuantity(e) {
    const enterred = parseInt(e.target.value)

    if (enterred >= 0 && enterred < 100) {
      this.qty = enterred
    }
  }

  changeVariant(e) {
    this.variant = e.target.value

    const option = e.target.options[e.target.selectedIndex]
    this.variantPrice = parseFloat(option.getAttribute('data-price'))
    this.variantId = parseInt(option.getAttribute('data-sku'))
  }

  addToBasket() {
    if (!this.variantId) {
      alert("Please select an option before adding this product to your basket")
    } else {
      this.updateQuantity()
    }
  }
}
