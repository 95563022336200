const BASKET_KEY = "ceBasket"

Number.prototype.toMoney = function(decimals, decimal_sep, thousands_sep) { 
   var n = this,
   c = isNaN(decimals) ? 2 : Math.abs(decimals), //if decimal is zero we must take it, it means user does not want to show any decimal
   d = decimal_sep || '.', //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

   /*
   according to [https://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
   the fastest way to check for not defined parameter is to use typeof value === 'undefined' 
   rather than doing value === undefined.
   */   
   t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep, //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

   sign = (n < 0) ? '-' : '',

   //extracting the absolute value of the integer part of the number and converting to string
   i = parseInt(n = Math.abs(n).toFixed(c)) + '', 

   j = ((j = i.length) > 3) ? j % 3 : 0; 
   return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : ''); 
}

export default class Basket {
  constructor() {
    this.items = []

    const stored = localStorage.getItem(BASKET_KEY)
    if (stored && stored.length > 0) {
      try {
        this.items = JSON.parse(stored)
      }
      catch {
        console.log("An error occurred when loading the basket")
      }
    }
  }

  static init() {
    if (window.basket) {
      return Basket.global()
    }

    if (!Basket.storageAvailable('localStorage')) {
      console.log("Local Storage not available")
      return
    }

    window.basket = new Basket()
  }

  static global() {
    if (!window.basket) {
      Basket.init()
    }

    return window.basket
  }

  totalQuantity() {
    let total = 0

    for (let i = 0; i < this.items.length; i++) {
      total += this.items[i].quantity
    }

    return total
  }

  quantity(sku) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].sku == sku) {
        return this.items[i].quantity
      }
    }

    return 0
  }

  setQuantity(properties, sku, quantity) {
    if (quantity < 1) {
      this.remove(sku)
    } else {
      // Does item already exist in the basket or need to be added
      let exists = false

      this.items = this.items.map(item => {
        if (item.sku == sku) {
          exists = true

          return Object.assign(item, {
            quantity: quantity
          })
        } else {
          return item
        }
      })

      if (!exists) {
        this.items.push(Object.assign(properties, {
          sku: sku,
          quantity: quantity
        }))
      }

      this.persist()
    }

    if (this.handler) {
      this.handler(this.totalQuantity())
    }
  }

  remove(sku) {
    this.items = this.items.filter(item => {
      if (sku == item.sku) {
        return false
      } else {
        return true
      }
    })

    this.persist()
  }

  clear() {
    this.items = []
    localStorage.removeItem(BASKET_KEY)
  }

  persist() {
    const serialized = JSON.stringify(this.items)
    localStorage.setItem(BASKET_KEY, serialized)
  }

  static storageAvailable(type) {
    var storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
  }
}

