/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
import Basket from '../utils/basket'

$(function() {
  var basket = Basket.global()

  var updateMiniBasket = function(count) {
    if (count > 0) {
      $('.mini-basket__count').show()
      $('.mini-basket__count').text(count)
    } else {
      $('.mini-basket__count').hide()
    }
  }

  if (basket) {
    basket.handler = updateMiniBasket
    updateMiniBasket(basket.totalQuantity())
  }

  if ($('#checkout-thanks').length) {
    basket.clear()
    updateMiniBasket(basket.totalQuantity())
  }
})
